/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import type { AdventCalendarDoor as AdventCalendarDoorType } from "shop-state/types";
import type { HolidayType } from "..";

type Props = {
  door: AdventCalendarDoorType,
  closed: boolean,
  holidayType?: HolidayType,
};

const AdventCalendarDoor = ({ door, closed, holidayType }: Props): React$Node => {
  if (door.__typename === "AdventCalendarDoorToday") {
    return (
      <Link
        className={cn(styles.door, styles.doorToday, styles.doorOpen, styles[holidayType])}
        to={door.category.url}
      >
        {(holidayType === "easter") &&
          <img src={door.category.image ? door.category.image.x1 : ""} alt="" />
        }
        {(holidayType === "christmas") && (
          <>
            <div className={styles.door__bg} />
            <span className={styles.door__day}>{door.day}</span>
          </>
        )}
      </Link>
    );
  }

  if (door.__typename === "AdventCalendarDoorOpen" && !closed) {
    return (
      <Link
        className={cn(styles.door, styles.doorOpen, styles[holidayType])}
        to={door.category.url}
      >
        {(holidayType === "easter") &&
          <img src={door.category.image ? door.category.image.x1 : ""} alt="" />
        }
        {(holidayType === "christmas") && (
          <>
            <div className={styles.door__bg} />
            <span className={styles.door__day}>{door.day}</span>
          </>
        )}
      </Link>
    );
  }

  return (
    <div className={cn(styles.door, styles.doorClosed, styles[holidayType])}>
      {(holidayType === "easter") &&
        <img src={door.image ? door.image.x1 : ""} alt="" />
      }
      {(holidayType === "christmas") && (
        <>
          <div className={styles.door__bg} />
          <span className={styles.door__day}>{door.day}</span>
        </>
      )}
    </div>
  );
};

export default AdventCalendarDoor;
