/* @flow */

import { useContext } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";

type UseFormatReturn = {|
  formatBasicPoints: (points: number) => string,
  formatDate: (date: string) => string,
  formatExtraPoints: (points: number) => string,
  formatPoints: (points: number) => string,
  formatPrice: (price: number | string) => string,
  formatPriceLong: (
    price: number | string,
    currency: ?string
  ) => string,
|};

// Finds groups of three digits in a number.
const MATCH_NUMBER_SET = /\B(?=(\d{3})+(?!\d))/g;

// Formats a number-as-string into 12 345.67 format.
export const formatNumber = (n: string): string =>
  n.replace(".", ",").replace(MATCH_NUMBER_SET, " ");

const formatDate = (date: string): string => {
  const dt = new Date(date);
  return `${dt.getFullYear()}-${("00" + dt.getMonth()).slice(-2)}-${("00" + dt.getDate()).slice(-2)}`;
};

const useFormat = (): UseFormatReturn => {
  const t = useTranslate();
  const { info } = useContext(StoreInfoContext);
  const currencyCode = info.baseCurrencyCode;

  return {
    formatPriceLong: (price: number | string, currency: ?string) => {
      const curr = currency ? currency : currencyCode;
      return formatNumber(String(Number.parseFloat(String(price)).toFixed(curr === "EUR" ? 2 : 0))) + " " + curr;
    },
    formatPrice: (price: number | string) =>
      // Max 2 decimals, and skip any trailing zeroes
      formatNumber(String(Number.parseFloat(String(price)).toFixed(currencyCode === "EUR" ? 2 : 0))) + " " + t(`CURRENCY_SHORT.${currencyCode}`),
    formatPoints: (points: number) =>
      formatNumber(Number.parseFloat(String(points)).toFixed(0)) + " " + t("LOCALE.POINTS"),
    formatBasicPoints: (points: number) =>
      formatNumber(Number.parseFloat(String(points)).toFixed(0)) + " " + t("LOCALE.BASIC_POINTS"),
    formatExtraPoints: (points: number) =>
      formatNumber(Number.parseFloat(String(points)).toFixed(0)) + " " + t("LOCALE.EXTRA_POINTS"),
    formatDate,
  };
};

export default useFormat;
