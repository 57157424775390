/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import cn from "classnames";
import useFormat from "helpers/use-format";

type Props = {
  price: number,
  msrp: number,
  className?: string,
  productType: "simple" | "virtual" | "bundle" | "configurable",
  hasSelection: boolean,
  salesLabel: ?string,
};

const Price = ({
  price,
  msrp,
  className,
  productType,
  hasSelection,
  salesLabel,
}: Props): React$Node => {
  const { formatPrice } = useFormat();
  const t = useTranslate();

  return (
    <span className={cn(styles.block, className)}>
      <span className={styles.price}>
        <span>
          {(price < msrp && salesLabel) ? salesLabel : t("PRICE.SHOP_PRICE")}
          {((productType === "configurable" || productType === "bundle") && !hasSelection) ? ` ${t("PRICE.FROM")}` : null}
        </span>
        <span>{formatPrice(price)}</span>
      </span>
      <span className={styles.msrp}>
        {msrp > 0 ?
          <>
            <span>
              {t("PRICE.MSRP")}
              {((productType === "configurable" || productType === "bundle") && !hasSelection) ? ` ${t("PRICE.FROM")}` : null}
            </span>
            <span>{formatPrice(msrp)}</span>
          </> :
          <>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
          </>
        }
      </span>
    </span>
  );
};

export default Price;
