/* @flow */

import type { TopCategory, ProductCardProduct, Product } from "shop-state/types";
import { useEffect, useState } from "react";

import { freeShippingBreakpoint } from "config";

type Error = {
  field: string,
  error: string,
};

declare type DOMEvent<T> = {
  target: T,
} & Event;

export const focusInvalidField = (e: SyntheticEvent<HTMLFormElement>, errors: Array<Error>) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

export const loadScript = (isLoaded: () => boolean, src: string, cb: () => void): void => {
  if (!process.browser) {
    return;
  }

  if (isLoaded()) {
    cb();

    return;
  }

  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.src = src;

  /* eslint-disable unicorn/prefer-add-event-listener */
  script.onload = () => {
    script.onload = null;

    cb();
  };
  /* eslint-enable unicorn/prefer-add-event-listener */

  if (head) {
    /* eslint-disable unicorn/prefer-dom-node-append */
    head.appendChild(script);
    /* eslint-enable unicorn/prefer-dom-node-append */
  }
};

export const useCurrentSrc = (ref: { current: ?HTMLImageElement }, defaultSrc: string): string => {
  const [image, setImage] = useState(defaultSrc);

  useEffect(() => {
    const { current } = ref;

    if (current && current.currentSrc) {
      setImage(current.currentSrc);
    }
  }, [ref]);

  return image;
};

export const isValidHex = (hex: string): boolean => /^[0-9A-F]{6}$/i.test(hex);

export const sortCategories = (
  categories: $ReadOnlyArray<TopCategory>,
  locale: string): Array<TopCategory> => {
  return categories.slice().sort((a, b) => a.name.localeCompare(b.name, locale));
};

export const filterCategories = (
  categories: $ReadOnlyArray<TopCategory>): Array<TopCategory> => {
  return categories
    .filter(c => c.products.totalCount > 0)
    .map(c => ({ ...c, children: c.children.filter(cc => cc.products.totalCount > 0) }));
};

export const removeExampleEmail = <T: { email: ?string }>(object: T): T => {
  if (object && object.email && /@example.com$/.test(object.email)) {
    return {
      ...object,
      email: null,
    };
  }

  return object;
};

export const isShippingFree = (current: number): boolean => {
  return current > freeShippingBreakpoint;
};

export const productIsExternalShowcase = (product: ProductCardProduct | Product): boolean =>
  Boolean(product.attributes.externalShowcaseUrl);

export const getLowestMSRP = (product: ProductCardProduct | Product): ?number => {
  if (product.type === "configurable") {
    const cheapest = product.options.items.reduce((a, c) => {
      const { msrp } = c.product.attributes;
      if (typeof msrp === "number") {
        if (a === null) {
          return msrp;
        }

        if (msrp < a) {
          return msrp;
        }
      }

      return a;
    }, null);

    return cheapest;
  }

  return product.attributes.msrp;
};

/**
 * Change MSRP on the product.
 *
 * Use value set in `originalPrice` as the products MSRP.
 */
export const updateMSRPOnProduct = (product: ProductCardProduct | Product) => {
  product.attributes.msrp = product.originalPrice.incVat;
};
