/* @flow */

import React from "react";
import { useData } from "crustate/react";
import { useAnalytics } from "context/analytics";
import { quote as quoteQuery, upsell } from "queries";
import { useClient } from "entrypoint/shared";

import { QuoteData } from "data";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import { CheckoutCart } from "@crossroads/shop-views";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import ProductCard from "components/ProductCard";
import useFormat from "helpers/use-format";

const Cart = (): React$Node => {
  const quote = useData(QuoteData);
  const analytics = useAnalytics();
  const { formatPrice } = useFormat();

  if (!quote.data) {
    return null;
  }

  return (
    <CheckoutCart
      analytics={{ context: analytics, hasAlternativeContext: true }}
      QuoteData={QuoteData}
      formatPrice={formatPrice}
      setQuoteDiscountCode={setQuoteDiscountCode}
      removeQuoteDiscountCode={removeQuoteDiscountCode}
      quoteQuery={quoteQuery}
      useClient={useClient}
      RedeemCardField={null}
      BottomColumnSecond={<CustomerServiceLink />}
      hasBrandView={false}
      suggestedProductsQuery={upsell}
      ProductCardComponent={ProductCard}
    />
  );
};

export default Cart;
