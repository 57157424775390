/* @flow */

import type { Node as ReactNode } from "react";
import React, { useContext, useRef, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import Cookies from "js-cookie";
import CloseIcon from "icons/close-small.svg";
import CartMini from "components/CartMini";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { ViewModeData, CmsData } from "data";
import { UiProvider, useUi } from "helpers/ui";
import { Dialogue, NotificationBar } from "@crossroads/ui-components";
import useCookie from "helpers/use-cookie";
import CookieConsent from "components/CookieConsent";

import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";

type Props = {
  children: ReactNode,
};

const App = ({ children }: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const history = useHistory();
  const container = useRef(null);
  const { info } = useContext(StoreInfoContext);
  const onCheckout = location.pathname.includes("/checkout") && location.pathname !== "/checkout/success";
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host.replace(/:\d+$/, "") : ""}`, expires: 3650 };
  const [consent, setConsent, consentRead] = useCookie("cookie-consent", "initial", cookieOpts);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);
  const [toggleCookieDialogue, setToggleCookieDialogue] = useState(false);

  const { hamburgerOpen, sortOCMOpen, filterOCMOpen } = useUi();
  const [couponNotice, setCouponNotice] = useState(false);

  usePreserveScrollPosition(history);

  useEffect(() => {
    if (Cookies.get("coupon") !== undefined && Cookies.get("coupon_message_shown") === "false") {
      setCouponNotice(true);
      Cookies.set("coupon_message_shown", "true");
    }
  }, []);

  return (
    <>
      <div
        ref={container} className={styles.block}
      >
        <Helmet
          titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
          link={[
            { charset: "utf-8" },
            { property: "og:locale", content: "sv_SE" },
            { name: "description", content: info.defaultDescription || t("META.DESCRIPTION") },
            { property: "og:site_name", content: t("META.NAME") },
            { property: "og:title", content: t("META.TITLE") },
            { property: "og:card", content: "summary" },
            { property: "og:site", content: "@eon-shop" },
            { property: "og:creator", content: "@awardit" },
            { rel: "manifest", href: "/assets/browser/manifest.json" },
            { rel: "apple-touch-icon", sizes: "180x180", href: "/assets/browser/apple-touch-icon.png" },
            { rel: "icon", type: "image/png", href: "/assets/browser/favicon-16x16.png", sizes: "16x16" },
            { rel: "icon", type: "image/png", href: "/assets/browser/favicon-32x32.png", sizes: "32x32" },
            { rel: "mask-icon", href: "/assets/browser/safari-pinned-tab.svg", color: "#ea1c0a" },
          ]}
          meta={[
            { name: "msapplication-config", content: "/browserconfig.xml" },
            { name: "msapplication-TileColor", content: "#ffffff" },
            { name: "theme-color", content: "#ffffff" },
          ]}
          htmlAttributes={{ lang: info.locale.slice(0, 2) }}
        />
        <div className={styles.top}>
          <AppHeader />
          <div className={styles.height} />
          <div>
            {children}
          </div>

          {consentRead &&
            <div className={styles.notices}>
              <CookieConsent
                isVisible={consentRead && (consent === "initial" || toggleCookieDialogue)}
                consent={consent}
                setConsent={setConsent}
                consentRead={consentRead}
                setToggleCookieDialogue={setToggleCookieDialogue}
              />
            </div>
          }
        </div>
        {!onCheckout && (
          <AppFooter
            className={styles.footer}
            setToggleCookieDialogue={setToggleCookieDialogue}
          />
        )}
        {info.popUp && info.popUp.body && !hideNotificationBar &&
          <NotificationBar
            className={styles.notificationBar}
            text={info.popUp.body}
            onClose={() => setHideNotificationBar(true)}
          />
        }
      </div>

      {(!onCheckout && !hamburgerOpen && !sortOCMOpen && !filterOCMOpen) &&
        <CartMini />
      }

      {couponNotice && (
        <Dialogue
          open
          className={styles.confirmDialogue}
          setOpen={() => setCouponNotice(false)}
          title={t("COUPON_LIGHTBOX.TITLE")}
          closeIcon={<div className={styles.closeIconWrapper}><CloseIcon /></div>}
          primaryAction={t("COUPON_LIGHTBOX.CTA")}
          onPrimaryAction={() => setCouponNotice(false)}
        >
          {t("COUPON_LIGHTBOX.INFO")}
        </Dialogue>
      )}
    </>
  );
};

const AppOuter = ({ children }: {
  children: ReactNode,
}): React$Node => {
  return (
    <ViewModeData.Provider>
      <CmsData.Provider url="cookie-consent">
        <UiProvider>
          <App>
            {children}
          </App>
        </UiProvider>
      </CmsData.Provider>
    </ViewModeData.Provider>
  );
};

export default AppOuter;
