/* @flow */

import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.scss";

type Props = {
  categories: $ReadOnlyArray<{ name: string, url: string }>,
};

const SubCategories = ({ categories }: Props): React$Node => {
  return (
    <nav className={styles.block}>
      <ul className={styles.list}>
        {categories.map(x => (
          <li key={x.url} className={styles.item}>
            <NavLink
              className={styles.link}
              activeClassName={styles.isActive}
              to={{
                pathname: x.url,
                state: { hint: { type: "category", category: x } },
              }}
            >
              {x.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SubCategories;
