/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { HomeResponse } from "state/home";

import { HOME_REQUEST, HOME_RESPONSE } from "state/home";
import {
  home,
  popular,
  popularProductsHead as popularProductsHeadQ } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const [
        { categoryPreview1, categoryPreview2, hero1, hero2, hero3, adventCalendar },
        { popularProductsHead },
        { popularProducts },
      ] = await Promise.all([
        client(home, undefined, { cache: false }),
        client(popularProductsHeadQ, undefined, { cache: false }),
        client(popular, { filter: null, page: 1, pageSize: 3 }),
      ]);

      const heroArray = [hero1, hero2, hero3].reduce((acc, curr) => {
        if (curr) {
          acc.push(curr);
        }

        return acc;
      }, []);

      return ({
        tag: HOME_RESPONSE,
        data: {
          popularProductsPreview: popularProductsHead?.category ? {
            category: {
              ...popularProductsHead.category,
              url: "/popular-products",
              products: {
                items: popularProducts.items,
              },
            },
          } : null,
          categoryPreview1,
          categoryPreview2,
          hero: heroArray,
          adventCalendar,
        },
      }: HomeResponse);
    },
    subscribe: { [HOME_REQUEST]: true },
  });
};

export default registerClient;
