/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import bindHomeEffects from "./home";
import bindQuoteEffects from "./quote";
import bindRouteEffects from "./route";
import bindOrderEffects from "./order";
import bindPopularEffects from "./popular";
import bindCmsEffects from "./cms";
import bindSearchEffects from "./search";
import bindAdventCalendarEffects from "./advent-calendar";

type History = {
  push: (path: string) => void,
};

export const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  bindRouteEffects(storage, client);
  bindHomeEffects(storage, client);
  bindQuoteEffects(storage, client);
  bindOrderEffects(storage, client);
  bindPopularEffects(storage, client);
  bindCmsEffects(storage, client);
  bindSearchEffects(storage, client, history);
  bindAdventCalendarEffects(storage, client);
};
