/* @flow */

import type {
  SortableProductList,
  FilterableProductList as TFilterableProductList,
  FilterLocation,
  ProductSort,
} from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import { useUi } from "helpers/ui";
import ProductList from "components/ProductList";
import Button from "components/Button";
import PaginatedProductList from "components/PaginatedProductList";
import { PAGE_SIZE } from "effects/route";
import {
  OffCanvasFilterMenu,
  OffCanvasSortMenu,
  Filterbar,
  StickBelowHeader,
  useFilter,
} from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron.svg";
import CloseIcon from "icons/cross.svg";

import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
  category?: string,
};

type InnerProps = {
  updating: boolean,
  productList: SortableProductList | TFilterableProductList,
  load: FilterLocation => any,
  sortValues?: Array<ProductSort>,
};

const FilterableProductListInner = ({ updating, productList, load, sortValues }: InnerProps) => {
  const t = useTranslate();
  const { filterOCMOpen, setFilterOCMOpen, sortOCMOpen, setSortOCMOpen } = useUi();
  const filterConfig = {
    loading: updating,
    productList,
    usePoints: false,
    load,
    sortValues,
  };
  const filterState = useFilter(filterConfig);

  const FilterButton = () => (
    <Button
      className={styles.filterButton}
      size="medium"
      variant="ghost"
      onClick={() => setFilterOCMOpen(true)}
    >
      {t("FILTER.FILTERBAR.ALL_FILTERS")}
    </Button>
  );

  const SortButton = () => (
    <Button
      className={styles.sortButton}
      size="medium"
      variant="ghost"
      onClick={() => setSortOCMOpen(true)}
    >
      {t("FILTER.FILTERBAR.SORT")}
    </Button>
  );

  return (
    <>
      <OffCanvasFilterMenu
        isOpen={filterOCMOpen}
        close={() => setFilterOCMOpen(false)}
        filterState={filterState}
        ChevronIcon={ChevronIcon}
        CloseIcon={CloseIcon}
        side="RIGHT"
      />

      <OffCanvasSortMenu
        isOpen={sortOCMOpen}
        close={() => setSortOCMOpen(false)}
        filterState={filterState}
        side="RIGHT"
        CloseIcon={CloseIcon}
      />

      {sortValues && sortValues.length > 0 ?
        <Filterbar
          Button={FilterButton}
          SortButton={SortButton}
          sortButtonSide="right"
          filterState={filterState}
          openFilters={() => setFilterOCMOpen(true)}
        /> :
        <Filterbar
          Button={FilterButton}
          sortButtonSide="right"
          filterState={filterState}
          openFilters={() => setFilterOCMOpen(true)}
        />
      }
    </>
  );
};

const BLACKLISTED_SORT_VALUES = new Set(["position"]);

const useGetSortValues = (productList: SortableProductList | TFilterableProductList) => {
  const t = useTranslate();

  if (!productList.sortableBy) {
    return [];
  }

  const sortableBy = productList.sortableBy
    .filter(sb => !BLACKLISTED_SORT_VALUES.has(sb.code));

  const sortValues = [
    {
      code: ``,
      label: t("FILTER.FILTERBAR.SORT_DEFAULT"),
    },
  ];

  for (const value of sortableBy) {
    sortValues.push({
      code: `${value.code}_asc`,
      label: `${value.label} ↑`,
    }, {
      code: `${value.code}_desc`,
      label: `${value.label} ↓`,
    });
  }

  return sortValues;
};

const FilterableProductList = ({
  children,
  updating,
  productList,
  load,
  breadcrumbLinks,
  category }: Props): React$Node => {
  const numPages = Math.ceil(productList.totalCount / PAGE_SIZE);
  const sortValues = useGetSortValues(productList);

  const categoryTitle = category ? category : (sortValues.length > 0 && !category ? sortValues[0].label : "");

  return (
    <>
      <FilterableProductListInner
        productList={productList}
        sortValues={sortValues}
        numPages={numPages}
        updating={updating}
        load={load}
      />
      {children}
      <Wrapper>
        <PaginatedProductList
          updating={updating}
          numPages={numPages}
          productList={productList}
          breadcrumbLinks={breadcrumbLinks}
          category={categoryTitle}
        />
      </Wrapper>
    </>
  );
};

export const FilterableProductListHint = ({ children }: { children?: React$Node }): React$Node => (
  <Wrapper>
    <StickBelowHeader className={styles.filterbar}>
      <div className={styles.filterBarHint} />
    </StickBelowHeader>
    {children}
    <ProductList products={[null, null, null, null, null, null, null, null]} />
  </Wrapper>
);

export default FilterableProductList;
