/* @flow */

/*
  Visually indicate how close the user is to free shipping.
*/

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import { isShippingFree } from "helpers/utils";
import { freeShippingBreakpoint } from "config";

type Props = {
  className?: string,
  current: number,
};

const FreeShippingMeter = ({ current, className }: Props): React$Node => {
  const t = useTranslate();
  const { formatPrice } = useFormat();

  return (
    <div className={cn(className, styles.block)}>
      <div className={styles.bar}>
        <div
          className={styles.progress}
          style={{
            transform: `translateX(${Math.min(current / freeShippingBreakpoint * 100, 100)}%)`,
          }}
        />
      </div>

      <p className={styles.text}>
        {isShippingFree(current) ?
          t("CART.FREE_SHIPPING") :
          t("CART.FREE_SHIPPING_AT", { left: formatPrice(freeShippingBreakpoint - current) })
        }
      </p>
    </div>
  );
};

export default FreeShippingMeter;
