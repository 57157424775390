/* @flow */

import type { PopularProducts } from "state/popular";

import React from "react";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { useLocation } from "react-router-dom";
import { PopularData } from "data";
import FilterableProductList, { FilterableProductListHint } from "components/FilterableProductList";
import ProductListHero, { ProductListHeroHint } from "components/ProductListHero";
import { load } from "state/popular";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import styles from "./styles.scss";

type Props = {
  content: PopularProducts,
  updating: boolean,
};

const PopularViewWrapper = (): React$Node => {
  const popular = useData(PopularData);

  if (popular.state === "LOADED" || popular.state === "UPDATING") {
    return (
      <PopularView
        content={popular.data}
        updating={popular.state === "UPDATING"}
      />
    );
  }

  return <HintPopularView />;
};

const PopularView = ({ content, updating }: Props) => {
  const sendMessage = useSendMessage();
  const loadFn = location => sendMessage(load(location, true));
  const breadcrumbLinks = useBreadcrumbLinks();

  return (
    <div className={styles.block}>
      <Helmet title={content.name} />

      <ProductListHero
        title={content.name}
        description={content.description}
        totalCount={content.items.totalCount}
        breadcrumbLinks={breadcrumbLinks}
      />

      <FilterableProductList
        updating={updating}
        productList={content.items}
        load={loadFn}
        category={content.name}
      />
    </div>
  );
};

const HintPopularView = () => {
  const breadcrumbLinks = useBreadcrumbLinks();
  const { state } = useLocation();
  const title = state?.hint?.category?.name || "";

  return (
    <div className={styles.block}>
      <Helmet title={title} />
      <ProductListHeroHint
        title={title}
        breadcrumbLinks={breadcrumbLinks} />
      <FilterableProductListHint />
    </div>
  );
};

export default PopularViewWrapper;
