/* @flow */

import React from "react";
import styles from "./styles.scss";
import classNames from "classnames";

type Props = {
  className?: string,
  children: React$Node,
  element?: string,
  modifiers?: string,
  ...
};

const FloatingMessage = ({
  children,
  className,
  element = "div",
  modifiers = "bottom left",
  ...rest }: Props): React$Node => {
  const Elem = element;

  return (
    <Elem
      {...rest}
      className={classNames(
        className,
        styles.block,
        ...modifiers.split(" ").map(m => styles[m])
      )}
    >

      {children}
    </Elem>
  );
};

export default FloatingMessage;
