/* @flow */

import React, { useState } from "react";
import { useClient } from "entrypoint/shared";
import { Form } from "@awardit/formaggio";
import { useTranslate } from "@awardit/react-use-translate";
import { addMessage } from "@crossroads/shop-state/messages";
import { useSendMessage } from "crustate/react";
import styles from "./styles.scss";
import { Button } from "@crossroads/ui-components";
import Field from "components/Field";
import { subscribeToNewsletter } from "queries";
type Props = {
  paragraph?: string,
};

const RegisterForm = ({ paragraph }: Props): React$Node => {
  const client = useClient();
  const sendMessage = useSendMessage();
  const [state, setState] = useState({ email: "" });
  const [processing, setProcessing] = useState(false);
  const t = useTranslate();

  const submit = async e => {
    e.preventDefault();
    setProcessing(true);

    const {
      subscribeToNewsletter: { result },
    } = await client(subscribeToNewsletter, { email: state.email });

    if (result !== "success") {
      sendMessage(addMessage(`ADVENT_CALENDAR.${result}`, "error"));
    }
    else {
      sendMessage(addMessage("ADVENT_CALENDAR.success", "success"));
      setState({ email: "" });
    }

    setProcessing(false);
  };

  return (
    <div className={styles.registerForm}>

      {paragraph && (
        <p className={styles.paragraph}>
          {paragraph}
        </p>
      )}

      <Form
        className={styles.form}
        value={(state: any)}
        onChange={x => {
          setState({ ...state, ...(x: any) });
        }}
        onSubmit={submit}
      >
        <Field
          required
          name="email"
          wrapperClassName={styles.input}
          variant="float"
          label={t("ADVENT_CALENDAR.FORM.EMAIL")}
          disabled={processing}
        />
        <Button
          loading={processing}
          variant="primary"
        >
          {t("ADVENT_CALENDAR.FORM.SUBMIT")}
        </Button>
      </Form>
    </div>
  );
};

export default RegisterForm;
