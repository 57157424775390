/* @flow */

import React, { useEffect } from "react";
import { useLocation } from "react-router";
import usePrevious from "helpers/use-previous";
import { useTranslate } from "@awardit/react-use-translate";
import Nav from "./Nav";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import { MODE } from "state/view-mode";
import { useUi } from "helpers/ui";
import SystemMessages from "components/SystemMessages";

import styles from "./styles.scss";

const AppHeader = (): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const { setHamburgerOpen, setSubNavOpen, setViewMode } = useUi();
  const quoteData = useData(QuoteData);
  const prevQuoteData = usePrevious(quoteData);

  // Open mini cart when adding to cart
  useEffect(() => {
    if (location.pathname === t("URL.CHECKOUT_CART") ||
        !prevQuoteData ||
        (quoteData.state !== "LOADED" || prevQuoteData.state !== "ADDING_ITEM")) {
      return;
    }

    const count = quoteData.data.items.reduce((a, { qty }) => a + qty, 0);
    const prevCount = prevQuoteData.data.items.reduce((a, { qty }) => a + qty, 0);

    if (count > prevCount) {
      setViewMode(MODE.CART);
      setSubNavOpen(false);
      setHamburgerOpen(false);
    }
  }, [setViewMode, prevQuoteData, quoteData, location, setSubNavOpen, setHamburgerOpen]);

  return (
    <div className={styles.block}>
      <SystemMessages />
      <Nav />
    </div>
  );
};

export default AppHeader;
