/* @flow */

import React from "react";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import TryggEHandelIcon from "icons/trygg-e-handel.svg";
import Button from "components/Button";

import styles from "./styles.scss";

type Props = {
  className: string,
  setToggleCookieDialogue: (value: boolean) => void,
};

const AppFooter = ({ className = "", setToggleCookieDialogue }: Props): React$Node => {
  const t = useTranslate();

  return (
    <Wrapper>
      <div className={cn(styles.block, className)}>
        <p dangerouslySetInnerHTML={{ __html:
          t("FOOTER.COPYRIGHT", { year: new Date().getFullYear() }),
        }} />
        <div className={styles.cookieLink}>
          <Button
            className={styles.link}
            onClick={() => setToggleCookieDialogue(true)}
          >
            {t("FOOTER.NEED_HELP.COOKIES")}
          </Button>
        </div>
        <TryggEHandelIcon />
      </div>
    </Wrapper>
  );
};

export default AppFooter;
