/* @flow */

/*
  Display category/brand navigation as clickable cards
*/

import type { TopCategory } from "shop-state/types";

import React from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { Link } from "react-router-dom";

import IconEnergiSmart from "icons/energismart.svg";
import IconInomhus from "icons/inomhus_2.svg";
import IconTradgard from "icons/tradgard_1.svg";
import IconPresentkort from "icons/presentkort.svg";
import IconSolcellsdrivet from "icons/solcellsdrivet.svg";

type Props = {
  className?: string,
  categories: $ReadOnlyArray<TopCategory>,
};

type Category = {
  +name: string,
  +url: string,
  +icon: React$Node,
};

const getCategoryIcon = (url: string): React$Node => {
  switch (url) {
    case "/energismart": return <IconEnergiSmart className={styles.icon} />; // OK
    case "/hemmet": return <IconInomhus className={styles.icon} />; // OK
    case "/presentkort": return <IconPresentkort className={styles.icon} />; // OK
    case "/tradgard-balkong": return <IconTradgard className={styles.icon} />; // OK
    case "/energismart/solcellsdrivet": return <IconSolcellsdrivet className={styles.icon} />; // stämmer id i gamla?
    default: return null;
  }
};

const getCategory = (category: { +name: string, +url: string }): ?Category => {
  const icon = getCategoryIcon(category.url);

  if (icon === null) {
    return null;
  }

  return { name: category.name, url: category.url, icon };
};

const getCategoriesWithIcons = (categories: $ReadOnlyArray<TopCategory>): Array<Category> =>
  categories
    .reduce((acc, curr) => {
      let category = getCategory(curr);

      if (category) {
        acc.push(category);
      }

      curr.children.forEach(c => {
        category = getCategory(c);

        if (category) {
          acc.push(category);
        }
      });

      return acc;
    }, []);

const CategoryCards = ({ className, categories }: Props): React$Node => {
  const _categories = getCategoriesWithIcons(categories);

  return (
    <ul className={cn(styles.list, className)}>
      {_categories.map((x, i) => (
        <li key={i} className={styles.item}>
          <Link
            to={{
              pathname: x.url,
              state: { hint: { type: "category", category: { name: x.name } } },
            }}
            title={x.name}
            draggable="false"
            className={styles.link}
          >

            {x.icon}

            <span className={styles.name}>
              {x.name}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default CategoryCards;
