/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";
import styles from "./styles.scss";
import LogoSVG from "icons/logo.svg";

type Props = {
  className?: string,
};

const Logo = ({ className }: Props): React$Node => {
  const t = useTranslate();

  return (
    <LogoSVG
      className={cn(styles.block, className)}
      alt={t("LOGO.ALT")}
      title={t("LOGO.TITLE")}
    />
  );
};

export default Logo;
