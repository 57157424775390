/* @flow */

import type { Category, HintCategory } from "shop-state/types";

import React from "react";
import { useData } from "crustate/react";
import { HomeData } from "data";
import { Wrapper, Button } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import RegisterForm from "../RegisterForm";
import styles from "./styles.scss";
import { Link } from "react-router-dom";

const AdventCalendarHero = ({ category }: { category: Category | HintCategory }): React$Node => {
  const t = useTranslate();
  const home = useData(HomeData);
  const parentCategoryBanner =
    home.state === "LOADED" &&
    home.data.adventCalendar?.category.clsCatfieldsGeneralImgL !== undefined &&
    home.data.adventCalendar.category.clsCatfieldsGeneralImgL !== null ?
      home.data.adventCalendar.category.clsCatfieldsGeneralImgL : null;

  return (
    <>
      <div
        className={styles.adventCalendarHero}
        style={parentCategoryBanner !== null ? { backgroundImage: `url(${parentCategoryBanner.x2 || ""})` } : {}}
      >
        <div className={styles.content}>
          {category.name && (
            <h1>{category.name}</h1>
          )}
          <p>{t("ADVENT_CALENDAR.DOOR_OPEN_X_DAYS", { days: 3 })}</p>
        </div>
      </div>
      <Wrapper>
        <div className={styles.bottom}>
          <Link to="/">
            <Button variant="ghost">
              {t("ADVENT_CALENDAR.BACK")}
            </Button>
          </Link>
          <RegisterForm />
        </div>
      </Wrapper>
    </>
  );
};

export default AdventCalendarHero;
