/* @flow */

import type { Product, ProductCardProduct, ProductCategory } from "shop-state/types";
import type { Option } from "@crossroads/ui-components";

import React, { useState, useEffect, useMemo } from "react";
import { useData } from "crustate/react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { useGetProductMeta } from "helpers/get-meta";
import useFormat from "helpers/use-format";
import { productIsExternalShowcase } from "helpers/utils";
import { QuoteData } from "data";
import styles from "./styles.scss";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import Breadcrumbs from "components/Breadcrumbs";
import QtyPicker from "components/QtyPicker";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import {
  ProductViewMedia,
  Button,
  AutoExpand,
  Foldable,
  AddToCart,
  productDefaults,
  inStock,
  getAttributesConfigurable,
  getSelectedConfigurable,
  getPrice } from "@crossroads/ui-components";
import Price from "components/Price";
import cn from "classnames";
import ChevronIcon from "icons/chevron.svg";
import CloseIcon from "icons/cross.svg";
import { useAnalytics } from "context/analytics";

type ProductViewProps = {
  product: Product,
};

type HintProductViewProps = {
  product: ProductCardProduct,
};

const Section = ({ title, children, initialyOpen = false }: {
  title: string,
  children: React$Node,
  initialyOpen?: boolean,
}) => {
  const [open, setOpen] = useState(initialyOpen);

  return (
    <section className={cn(styles.section, { [styles.section__open]: open })}>
      <button type="button" className={styles.sectionHeader} onClick={() => setOpen(!open)}>
        <span className={styles.descriptionHeading}>{title}</span>
        <ChevronIcon />
      </button>
      <Foldable open={open}>
        <div className={styles.sectionBody}>
          {children}
        </div>
      </Foldable>
    </section>
  );
};

const AddToCartBtn = ({
  loading = false, outOfStock,
}: { loading?: boolean, outOfStock: boolean,
}) => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const addingToCart = quoteState.state === "ADDING_ITEM";

  return (
    <Button
      className={styles.addToCartButton}
      variant="accent"
      loading={addingToCart || loading}
      disabled={outOfStock}
      type="submit"
    >
      {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
    </Button>
  );
};

const mapProductCategories = (categories: $ReadOnlyArray<ProductCategory>) => {
  const structuredCategories: Array<{
      name: string,
      url: string,
      children: Array<{
        name: string,
        url: string,
      }>,
  }> = [];

  for (const category of categories) {
    if (category.parent) {
      let parent = structuredCategories.find(c => c.url === category.parent?.url);

      if (parent) {
        parent.children.push({
          name: category.name,
          url: category.url,
        });
      }
      else {
        parent = {
          name: category.parent?.name || "",
          url: category.parent?.url || "",
          children: [{
            name: category.name,
            url: category.url,
          }],
        };

        structuredCategories.push(parent);
      }
    }
    else {
      structuredCategories.push({
        name: category.name,
        url: category.url,
        children: [],
      });
    }
  }

  return structuredCategories;
};

const ProductView = ({ product }: ProductViewProps): React$Node => {
  const location = useLocation();
  const [qty, setQty] = useState(1);
  const analytics = useAnalytics();
  const { formatPrice } = useFormat();
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const [selected, setSelected] = useState<Option>(productDefaults(product));
  const selectedItem = getSelectedConfigurable(selected, configAttributes);
  const brand = product.attributes.manufacturer;
  const { manufacturer } = product.attrDescriptions;
  const t = useTranslate();
  const meta = useGetProductMeta(product, t);
  const outOfStock = !inStock(product, selected);
  const { smallImage, largeImage } = product.attributes;
  const categories = useMemo(() => mapProductCategories(product.categories), [product]);
  const breadcrumbLinks = useBreadcrumbLinks({ fallbackCategories: categories });
  const { price: { incVat: price } } = (selectedItem ?? product);
  const { msrp } = (selectedItem?.product.attributes ?? product.attributes);
  const isExternalShowcase = productIsExternalShowcase(product);
  const hidePrice = product.attributes.externalShowcaseHidePrice ?? false;
  const [currentImage, setCurrentImage] = useState({
    smallImage: smallImage ? { x1: smallImage.x1, x2: smallImage.x2 } : null,
    largeImage: largeImage ? { x1: largeImage.x1, x2: largeImage.x2 } : null,
  });

  const onAdd = p => {
    if (selectedItem) {
      const incVat = getPrice(product, selected, "incVat");
      const exVat = getPrice(product, selected, "exVat");

      analytics.registerModifyCart({
        ...selectedItem.product,
        qty,
        sku: product.sku,
        price: { incVat, exVat, vat: incVat - exVat },
        categories: p.categories,
      }, 1, incVat);
    }
    else {
      analytics.registerModifyCart({
        ...p,
        qty,
      }, 1, p.price.incVat);
    }
  };

  useEffect(() => {
    analytics.registerProductDetailsView({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    },
    product.price.incVat,
    location.state?.list,
    location.state?.position);
  }, []);

  // Change image when selecting option
  useEffect(() => {
    if (selectedItem) {
      const a = selectedItem.product.attributes;

      if (a) {
        setCurrentImage({
          smallImage: a.smallImage ? { x1: a.smallImage.x1, x2: a.smallImage.x2 } : null,
          largeImage: a.largeImage ? { x1: a.largeImage.x1, x2: a.largeImage.x2 } : null,
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    setCurrentImage({
      smallImage,
      largeImage,
    });
  }, [smallImage, largeImage]);

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <div className={styles.split}>

        <div className={styles.left}>
          <ProductViewMedia
            CloseIcon={CloseIcon}
            alt={product.name}
            currentImage={currentImage}
            gallery={product.gallery}
            location={{ ...location, state: location.state ? location.state : {} }}
            galleryLocation="bottom"
          />
        </div>

        <div className={styles.right}>
          <header className={styles.header}>
            <h1 className={styles.name}>{product.name}</h1>
          </header>
          <section className={styles.addToCart}>
            {isExternalShowcase &&
              <Button
                className={styles.externalShowcaseButton}
                elem="a"
                variant="accent"
                href={product.attributes.externalShowcaseUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {product.attributes.externalShowcaseCta || t("PRODUCT.EXTERNAL_SHOWCASE_DEFAULT")}
              </Button>
            }
            {!hidePrice &&
              <Price
                className={styles.price}
                price={price}
                msrp={msrp}
                hasSelection={Boolean(selectedItem)}
                productType={product.type}
                salesLabel={product.attributes.salesLabel}
              />
            }

            {!isExternalShowcase &&
            <>
              <QtyPicker
                value={qty}
                setValue={setQty}
                min={1}
              />

              <div className={styles.totalsAndAddToCart}>
                <AddToCart
                  product={product}
                  selected={selected}
                  setSelected={setSelected}
                  qty={qty}
                  onAdd={onAdd}
                >

                  <span className={styles.totals}>
                    <span>Att betala</span>
                    <span>{formatPrice(price * qty)}</span>
                  </span>
                  <AddToCartBtn outOfStock={outOfStock} />
                </AddToCart>
              </div>
            </>
            }
          </section>

          <Section
            title={t("PRODUCT.DESCRIPTION")}
            initialyOpen={isExternalShowcase}
          >
            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.description}
            />
          </Section>

          {manufacturer && manufacturer.icon && manufacturer.description &&
            <Section title={t("PRODUCT.ABOUT_BRAND", { brand: manufacturer.title })}>
              <div className={styles.manufacturer}>
                <div className={styles.logo}>
                  <img
                    src={manufacturer ? manufacturer.icon : ""}
                    alt={`Logo ${brand}`}
                  />
                </div>
                <p>{manufacturer ? manufacturer.description : ""}</p>
              </div>
            </Section>
          }
        </div>

      </div>

      <AutoExpand>
        &nbsp;
        <div className={styles.lists}>
          <div className={styles.relatedList}>
            {product.relatedProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.relatedProducts.items}
                productsPerRow={2}
              />
            }
          </div>

          <div className={styles.historyList}>
            {product.crossSellProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.crossSellProducts.items}
                productsPerRow={2}
              />
            }
          </div>
        </div>
      </AutoExpand>
    </Wrapper>
  );
};

export const HintProductView = ({ product }: HintProductViewProps): React$Node => {
  const location = useLocation();
  const image = product.attributes.largeImage ||
  product.attributes.smallImage ||
  location.state?.image || { x1: "", x2: "" };
  const breadcrumbLinks = useBreadcrumbLinks();

  return (
    <Wrapper className={styles.hintWrapper}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />
      <div className={styles.split}>
        <div className={styles.left}>
          {image.x1 &&
            <img className={styles.imageHint} src={image.x1} />
          }
        </div>

        <div className={styles.right}>
          <header className={styles.header}>
            <h1 className={styles.name}>{product.name}</h1>
          </header>

          <div className={styles.dummyContent}>
            <div className={styles.top} />
            <div className={styles.middle} />
            <div className={styles.bottom} />
          </div>
        </div>

      </div>
    </Wrapper>
  );
};

export default ProductView;
