/* @flow */

import type { Category, HintCategory } from "shop-state/types";

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { getCategoryMeta } from "helpers/get-meta";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import ProductListHero, { ProductListHeroHint } from "components/ProductListHero";
import FilterableProductList, { FilterableProductListHint } from "components/FilterableProductList";
import AdventCalendarHero from "components/AdventCalendar/AdventCalendarHero";
import Button from "components/Button";
import SubCategories from "components/SubCategories";
import Wrapper from "components/Wrapper";
import { useData, useSendMessage } from "crustate/react";
import { RouteData } from "data";
import { load as loadRoute } from "@crossroads/shop-state/route";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import styles from "./styles.scss";

type CategoryProps = {
  category: Category,
};

type HintCategoryProps = {
  category: HintCategory,
};

const useGetCategoriesNav = (category: Category | HintCategory) => {
  const { categories } = useContext(StoreInfoContext);

  if (category.parent === null) {
    return category.children;
  }

  let siblingCategories = [];
  for (const c of categories) {
    if (category.parent && c.url === category.parent.url) {
      siblingCategories = c.children;
    }
  }

  return siblingCategories;
};

const CategoryView = ({ category }: CategoryProps): React$Node => {
  const t = useTranslate();
  const meta = getCategoryMeta(category);
  const route = useData(RouteData);
  const sendMessage = useSendMessage();
  const updating = route.state === "UPDATING";
  const load = location => sendMessage(loadRoute(location, true));
  const { pathname, search } = useLocation();
  const onAdventCalendar = pathname.includes(t("ADVENT_CALENDAR.CATEGORY_URL"));
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });
  const subCategoryNav = useGetCategoriesNav(category);

  return (
    <div className={styles.block}>
      <Helmet
        title={meta.title}
        meta={meta.data}
      />

      {onAdventCalendar ?
        <AdventCalendarHero category={category} /> :
        <>
          <ProductListHero
            title={category.name}
            description={category.description}
            totalCount={category.products.totalCount}
            breadcrumbLinks={breadcrumbLinks}
          />
          <Wrapper>
            <SubCategories categories={subCategoryNav} />
          </Wrapper>
        </>
      }
      <FilterableProductList
        updating={updating}
        productList={category.products}
        load={load}
        breadcrumbLinks={[...breadcrumbLinks, {
          pathname,
          search,
          title: category.name,
          hint: {
            name: category.name,
          },
        }]}
        category={category.name}
      />
      {onAdventCalendar &&
        <div className={styles.adventCalendarBack}>
          <Button
            variant="primary"
            to={"/" + t("ADVENT_CALENDAR.CATEGORY_URL")}
          >
            {t("ADVENT_CALENDAR.BACK")}
          </Button>
        </div>
      }
    </div>
  );
};

export const HintCategoryView = ({ category }: HintCategoryProps): React$Node => {
  const t = useTranslate();
  const { pathname } = useLocation();
  const onAdventCalendar = pathname.includes(t("ADVENT_CALENDAR.CATEGORY_URL"));
  const breadcrumbLinks = useBreadcrumbLinks({ current: category.name });
  const subCategoryNav = useGetCategoriesNav(category);

  if (onAdventCalendar) {
    return (
      <div className={styles.block}>
        <Helmet title={category.name} />
        <AdventCalendarHero category={category} />
        <FilterableProductListHint />
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Helmet title={category.name} />

      <ProductListHeroHint
        title={category.name}
        breadcrumbLinks={breadcrumbLinks} />

      <Wrapper>
        <SubCategories categories={subCategoryNav} />
      </Wrapper>

      <FilterableProductListHint />
    </div>
  );
};

export default CategoryView;
