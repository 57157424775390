/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";

const NotFound = (): React$Node => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Helmet title={t("404.TITLE")} />

      <div className={styles.body}>
        <div>
          <h1 className={styles.heading}>{t("404.HEADING")}</h1>
          <p className={styles.text}>{t("404.TEXT")}</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
