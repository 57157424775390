/* @flow */

import React from "react";
import Wrapper from "components/Wrapper";
import Spinner from "components/Spinner";
import styles from "./styles.scss";

const LoadingView = (props: ?{ text?: string }): React$Node => {
  return (
    <Wrapper className={styles.block}>
      <Spinner />
      {props?.text &&
        <p>
          {props.text}
        </p>
      }
    </Wrapper>
  );
};

export default LoadingView;
