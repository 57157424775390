/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";
import styles from "./styles.scss";

import Button from "components/Button";

type Props = {
  className?: string,
  onClick: string => void,
};

const Hamburger = ({ className, onClick }: Props): React$Node => {
  const t = useTranslate();

  return (
    <Button
      className={cn(
        className,
        styles.block
      )}
      onClick={onClick}
    >

      <div className={styles.contain}>
        <span className={styles.icon} />

        <span className={styles.text}>
          {t("HAMBURGER.TITLE")}
        </span>
      </div>
    </Button>
  );
};

export default Hamburger;
