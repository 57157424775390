module.exports = {
  host: "localhost",
  port: 9147,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["UA-5572085-71", "G-D3L23KD38R", "G-NFF1QB4SQG"] },
    { store: "no", currencyCode: "NOK", accounts: ["UA-5572085-71", "G-D3L23KD38R", "G-NFF1QB4SQG"] },
    { store: "dk", currencyCode: "DKK", accounts: ["UA-5572085-71", "G-D3L23KD38R", "G-NFF1QB4SQG"] },
    { store: "en", currencyCode: "EUR", accounts: ["UA-5572085-71", "G-D3L23KD38R", "G-NFF1QB4SQG"] },
  ],
  isProduction: true,
  proxyHost: null, 
  altapay: {
    src: "https://awardit.altapaysecure.com/checkout/v1/js/AltaPaySDK.js",
  },
  freeShippingBreakpoint: 500,
  cspUri: "https://csp-report.browser-intake-datadoghq.eu/api/v2/logs?dd-api-key=pub552607268219ad5b8d2647030dd2f46f&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=service%3Ashop.eon.se%2Cenv%3Aproduction",
};
