/* @flow */

import type { StateData } from "crustate/react";

import { createStateData } from "crustate/react";

import { HomeModel } from "state/home";
import { MessagesModel } from "@crossroads/shop-state/messages";
import { OrderModel } from "state/order";
import { PopularModel } from "state/popular";
import { CmsModel } from "state/cms";
import { QuoteModel } from "state/quote";
import { RouteModel } from "@crossroads/shop-state/route";
import { SearchModel } from "@crossroads/shop-state/search";
import { ViewModeModel } from "state/view-mode";
import { AdventCalendarModel } from "state/advent-calendar";

export const HomeData: StateData<typeof HomeModel> =
  createStateData<typeof HomeModel>(HomeModel);

export const MessagesData: StateData<typeof MessagesModel> =
  createStateData<typeof MessagesModel>(MessagesModel);

export const OrderData: StateData<typeof OrderModel> =
  createStateData<typeof OrderModel>(OrderModel);

export const PopularData: StateData<typeof PopularModel> =
createStateData<typeof PopularModel>(PopularModel);

export const CmsData: StateData<typeof CmsModel> =
createStateData<typeof CmsModel>(CmsModel);

export const QuoteData: StateData<typeof QuoteModel> =
createStateData<typeof QuoteModel>(QuoteModel);

export const RouteData: StateData<typeof RouteModel> =
createStateData<typeof RouteModel>(RouteModel);

export const SearchData: StateData<typeof SearchModel> =
createStateData<typeof SearchModel>(SearchModel);

export const ViewModeData: StateData<typeof ViewModeModel> =
createStateData<typeof ViewModeModel>(ViewModeModel);

export const AdventCalendarData: StateData<typeof AdventCalendarModel> =
createStateData<typeof AdventCalendarModel>(AdventCalendarModel);

