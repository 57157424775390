/* @flow */

import React, { useState, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Foldable } from "@crossroads/ui-components";
import NavToggle from "components/NavToggle";

type Props = {
  className?: string,
};

const OffCanvasCategories = ({ className }: Props): React$Node => {
  const [visibleId, setVisibleId] = useState(null);
  const { categories } = useContext(StoreInfoContext);

  const toggle = (url: string) =>
    setVisibleId(visibleId === url ? null : url);

  return (
    <ul className={className}>
      {categories.map((x, i) => (
        <li key={i}>
          <NavToggle
            isParent
            hasButton={x.children.length > 0}
            open={visibleId === x.url}
            toggle={() => toggle(x.url)}
            isFirst={i === 0}
            level={1}
            url={x.url}
          >
            {x.name}
          </NavToggle>

          {x.children.length > 0 &&
            <Foldable open={visibleId === x.url}>
              {x.children.map((c, i) => (
                <NavToggle
                  key={c.url}
                  url={c.url}
                  level={2}
                  parentOpen={visibleId === x.url}
                  open={false}
                  hasButton={false}
                  isFirst={i === 0}
                >
                  {c.name}
                </NavToggle>
              ))}
            </Foldable>
          }
        </li>
      ))}
    </ul>
  );
};

export default OffCanvasCategories;
