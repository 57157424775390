/* @flow */

import type { CategoryPreviewFull as TCategoryPreviewFull } from "shop-state/types";

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";
import Button from "components/Button";
import ProductCard from "components/ProductCard";

import styles from "./styles.scss";

type Props = {
  className?: string,
  direction?: "left" | "right",
  category: TCategoryPreviewFull,
};

type WholeCategoryButtonProps = {
  className?: string,
  url: string,
  name: string,
};

const WholeCategoryButton = ({ className, url, name }: WholeCategoryButtonProps) => {
  const t = useTranslate();

  return (
    <Button
      className={className}
      variant="accent"
      to={{
        pathname: url,
        state: { hint: {
          type: "category",
          category: {
            name,
          },
        } },
      }}
    >
      {t("HOMEVIEW.WHOLE_CATEGORY")}
    </Button>
  );
};

const CategoryPreview = ({ className, direction = "left", category }: Props): React$Node => {
  if (!category.category || category.category.products.items.length === 0) {
    return null;
  }

  const { products, name, description, url } = category.category;

  return (
    <div className={cn(styles.block, styles[`block__${direction}`], className)}>
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={styles.infoInner}>
            <h2>{name}</h2>
            <p className={styles.description}>{description}</p>

            <WholeCategoryButton name={name} url={url} className={styles.buttonTop} />
          </div>
        </div>

        {products.items.map((p, i) =>
          <ProductCard key={p.name} product={p} list={name} position={i} />
        )}
      </div>

      <WholeCategoryButton name={name} url={url} className={styles.buttonBottom} />
    </div>
  );
};

export default CategoryPreview;
