/* @flow */

import { useLocation } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";

export type Step = {
  id: string,
  key: number,
  link: string,
  title: string,
  titleLong: string,
};

const useCheckoutSteps = (): {
  steps: Array<Step>,
  currentStep: ?Step,
} => {
  const t = useTranslate();
  const location = useLocation();

  const steps: Array<Step> = [
    {
      id: "cart",
      key: 1,
      link: t("URL.CHECKOUT_CART"),
      title: t("CHECKOUT.STEPS.CART.SHORT"),
      titleLong: t("CHECKOUT.STEPS.CART.LONG"),
    },
    {
      id: "overview",
      key: 2,
      link: t("URL.CHECKOUT_OVERVIEW"),
      title: t("CHECKOUT.STEPS.OVERVIEW.SHORT"),
      titleLong: t("CHECKOUT.STEPS.OVERVIEW.LONG"),
    },
  ];
  const currentStep = steps.find(s => s.link === location.pathname);

  return { steps, currentStep };
};

export default useCheckoutSteps;
