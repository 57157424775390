/* @flow */

import type { Match } from "react-router-dom";

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import SearchMenu from "../SearchMenu";
import Logo from "components/AppHeader/Logo";
import HamburgerMenu from "components/AppHeader/HamburgerMenu";
import Button from "components/Button";
import Hamburger from "components/Hamburger";
import { MODE } from "state/view-mode";
import { useUi } from "helpers/ui";
import HomeIcon from "icons/home.svg";
import SearchIcon from "icons/search.svg";
import ChevronIcon from "icons/chevron.svg";
import ArrowIcon from "icons/arrow.svg";
import useHeaderScroll from "helpers/use-header-scroll";
import { CollapsableHorizontalNav, useModal } from "@crossroads/ui-components";

import styles from "./styles.scss";

type HiddenCategoriesProps = {
  open: boolean,
  setOpen: boolean => void,
  categories: $ReadOnlyArray<{ +url: string, +name: string }>,
};

const headerHeight = Number.parseInt(styles.headerHeight, 10);

const navLinkIsActive = (match: Match, viewMode: string) => {
  if (!match) {
    return false;
  }

  if (viewMode !== MODE.NORMAL) {
    return false;
  }

  return true;
};

const HiddenCategories = ({ open, setOpen, categories }: HiddenCategoriesProps) => {
  const { Modal } = useModal(open, setOpen);
  const { viewMode } = useUi();

  return (
    <Modal>
      <div className={styles.hiddenCategories}>
        <Wrapper>
          {categories.map(x => (
            <NavLink
              key={x.url}
              to={x.url}
              activeClassName={styles.active}
              isActive={match => navLinkIsActive(match, viewMode)}
              onClick={() => setOpen(false)}
            >
              <ArrowIcon />
              <span>{x.name}</span>
            </NavLink>
          ))}
        </Wrapper>
      </div>
    </Modal>
  );
};

const NavLinks = () => {
  const t = useTranslate();
  const { viewMode } = useUi();
  const categoriesRef = useRef();
  const toggleRef = useRef();

  const { categories } = useContext(StoreInfoContext);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [moreCategoriesOpen, setMoreCategoriesOpen] = useState(false);

  return (
    <div ref={categoriesRef} className={styles.links}>
      <NavLink
        to="/"
        className={cn(styles.item, styles.item__home)}
      >
        <HomeIcon />
      </NavLink>

      <CollapsableHorizontalNav
        className={styles.collapsableNav}
        items={categories}
        setHiddenItems={setHiddenItems}
        renderItem={item => (
          <div key={item.url}>
            <NavLink
              to={item.url}
              activeClassName={styles.active}
              className={styles.item}
              isActive={match => navLinkIsActive(match, viewMode)}
            >
              <span>{item.name}</span>
            </NavLink>
          </div>
        )}
      >
        <div
          ref={toggleRef}
          className={cn(styles.item, styles.item__toggle, { [styles.open]: moreCategoriesOpen })}
        >
          <Button
            onClick={() => setMoreCategoriesOpen(!moreCategoriesOpen)}
          >
            {t("HEADER.CATEGORIES_TOGGLE")}
            <ChevronIcon />
          </Button>
        </div>
      </CollapsableHorizontalNav>

      <HiddenCategories
        categories={hiddenItems}
        open={moreCategoriesOpen}
        setOpen={setMoreCategoriesOpen} />
    </div>
  );
};

const Nav = (): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const {
    setHamburgerOpen,
    toggleHamburger,
    setSubNavOpen,
    viewMode,
    setViewMode,
    hamburgerOpen,
  } = useUi();

  const headerScroll = useHeaderScroll(headerHeight);
  const onCheckout = location.pathname.includes("/checkout") && location.pathname !== "/checkout/success";

  const toggleSearch = () =>
    setViewMode(viewMode === MODE.SEARCH ? MODE.NORMAL : MODE.SEARCH);

  // Close menus when switching page
  useEffect(() => {
    setViewMode(MODE.NORMAL);
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, setHamburgerOpen, location.pathname]);

  return (
    <div className={cn(
      styles[`mode__${viewMode}`],
      { [styles.hamburgerOpen]: hamburgerOpen }
    )}
    >
      <div className={cn(
        styles.block,
        { [styles.hidden]: headerScroll.isHidden }
      )}
      >
        <Wrapper className={styles.wrapper}>
          <div className={styles.wrapperInner}>
            <nav className={styles.menu}>
              {viewMode === MODE.SEARCH &&
                <div className={cn(styles.desktop, styles.subNav, styles.search)}>
                  <SearchMenu />
                </div>
              }
              <div className={styles.left}>
                {onCheckout && (
                  <NavLink
                    to="/"
                    className={cn(
                      styles.item,
                      styles.item__home,
                      { [styles.visible]: onCheckout }
                    )}
                  >
                    <HomeIcon />
                  </NavLink>
                )}
                {!onCheckout && (
                  <div className={styles.links}>
                    <Hamburger
                      className={cn(styles.item, styles.hamburger, styles.padding)}
                      onClick={toggleHamburger}
                    />
                    <NavLinks />
                  </div>
                )}
              </div>
              <div className={styles.right}>
                <nav className={styles.nav}>
                  {!onCheckout && (
                    <>
                      <NavLink
                        to={t("CUSTOMER_SERVICE.LINK")}
                        activeClassName={styles.active}
                        className={cn(styles.item, styles.item__customerService)}
                        isActive={match => navLinkIsActive(match, viewMode)}
                      >
                        <span>{t("CUSTOMER_SERVICE.ALT_TEXT")}</span>
                      </NavLink>

                      <div
                        className={cn(
                          styles.item,
                          styles.item__search,
                          { [styles.active]: viewMode === MODE.SEARCH }
                        )}
                        onClick={toggleSearch}
                      >
                        <SearchIcon />
                      </div>
                    </>
                  )}

                  <Link className={cn(styles.item__logo)} to="/">
                    <Logo
                      className={styles.logo}
                      onClick={() => setViewMode(MODE.NORMAL)}
                    />
                  </Link>
                </nav>
              </div>
            </nav>
          </div>
        </Wrapper>

        <HamburgerMenu />
      </div>
    </div>
  );
};

export default Nav;
