/* @flow */

/*
  NavLink + Button component.
*/

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import PlusIcon from "icons/plus.svg";
import MinusIcon from "icons/minus.svg";

type Props = {
  level: number,
  isFirst: boolean,
  hasButton: boolean,
  open: boolean,
  isParent?: boolean,
  parentOpen?: boolean,
  onClick?: () => void,
  toggle?: () => void,
  url: string,
  children: React$Node,
};

const getLinkClassNames = (level: number, isFirst: boolean) =>
  cn({
    [styles.clickable]: true,
    [styles.clickableTwo]: level === 2,
    [styles.clickableThree]: level >= 3,
    [styles.clickableFirst]: isFirst,
  });

const NavToggle = (props: Props): React$Node => {
  const {
    level,
    isFirst,
    hasButton,
    open,
    isParent,
    parentOpen,
    onClick,
    toggle,
    url,
    children,
  } = props;

  return (
    <div className={cn({ [styles.inset]: props.hasButton })}>
      <NavLink
        tabIndex={isParent !== true && parentOpen !== true ? -1 : 0}
        to={{
          pathname: url,
        }}
        activeClassName={styles.isActive}
        className={styles.link + " " + getLinkClassNames(level, isFirst)}
        onClick={onClick}
      >
        {children}
      </NavLink>

      {hasButton &&
        <button
          type="button"
          tabIndex={0}
          className={cn(
            getLinkClassNames(level, isFirst),
            styles.button
          )}
          onClick={toggle}
        >

          {open ?
            <MinusIcon className={styles.icon} /> :
            <PlusIcon className={styles.icon} />
          }
        </button>
      }
    </div>
  );
};

export default NavToggle;
