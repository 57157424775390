/* @flow */

import React, { useState } from "react";

import cn from "classnames";
import { useFormField } from "@awardit/formaggio";
import { Input, Checkbox, SearchableDropdown, DropdownItem } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import FloatingMessage from "components/FloatingMessage";

import CheckboxIcon from "icons/checkbox.svg";
import styles from "./styles.scss";

type InputProps = {
  name: string,
  className?: string,
};

type CheckboxProps = {
  ...React$ElementProps<"input">,
  className?: string,
  children: React$Node,
};

type DropdownProps = {
  ...React$ElementProps<"input">,
  name: string,
  items: Array<{ code: string, name: string }>,
  label?: string,
};

const Field = ({ ...props }: InputProps): React$Node => {
  const t = useTranslate();
  const [gotFocus, setGotFocus] = useState(false);
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");

  const inputError = ((submitted || dirty) && errors.length > 0) ? t(`VALIDATION.${errors[0].error}`) : "";

  return (
    <div>
      <Input
        {...props}
        {...inputProps}
        className={cn(props.className, inputError ? styles.fieldErrors : "")}
        onFocus={() => setGotFocus(true)}
        onBlur={() => setGotFocus(false)}
      />
      {inputError && !gotFocus &&
        <FloatingMessage
          element="label"
          htmlFor={props.name}
          className={styles.message}
        >
          <span className={styles.error}>
            {inputError}
          </span>
        </FloatingMessage>
      }
    </div>
  );
};

export default Field;

export const CheckboxField = ({ children, name, ...props }: CheckboxProps): React$Node => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(name, "");

  const inputError = ((submitted || dirty) && errors.length > 0) ? t(`VALIDATION.${errors[0].error}`) : "";

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          {...props}
          {...inputProps}
          className={styles.checkbox}
          CheckedComponent={CheckboxIcon}
          value={inputProps.value}
          name={name}
        />
        <label htmlFor={inputProps.id} style={{ paddingLeft: "0.7em" }}>
          {children}
        </label>

      </div>
      {inputError &&
        <FloatingMessage
          element="label"
          htmlFor={props.name}
          className={styles.checkboxMessage}
        >
          <span className={styles.error}>
            {inputError}
          </span>
        </FloatingMessage>
      }
    </>
  );
};

export const SearchableDropdownField = ({
  alwaysValidate = false,
  items,
  label,
  ...props }: DropdownProps): React$Node => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");

  const inputErrors = (submitted || dirty) || alwaysValidate === true ?
    errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <div className={styles.searchableDropdown}>
      <SearchableDropdown
        {...props}
        {...inputProps}
        value={inputProps.value.toString()}
        errors={inputErrors}
        onChange={x => inputProps.onChange((({ target: { value: x } }): any))}
      >
        {items.map(c => (
          <DropdownItem key={c.code} value={c.code}>
            {c.name}
          </DropdownItem>
        ))}
      </SearchableDropdown>
      {label &&
        <label
          className={cn(
            styles.searchableDropdownLabel,
            { [styles.searchableDropdownLabel__active]: inputProps.value.toString().length > 0 }
          )}
        >
          {label}
        </label>
      }
    </div>
  );
};
