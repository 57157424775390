/* @flow */

import React, { useRef, useEffect, useState } from "react";
import styles from "./styles.scss";
import { useHistory } from "react-router";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { useAnalytics } from "context/analytics";

type Props = {
  className?: string,
};

const SearchMenu = ({ className }: Props): React$Node => {
  const history = useHistory();
  const t = useTranslate();
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const analytics = useAnalytics();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onSubmit = (e: Event) => {
    e.preventDefault();

    if (inputRef.current !== null) {
      const { value } = inputRef.current;

      if (!value || value.length <= 2) {
        return;
      }

      history.push(`/search/${value}`);
      analytics.searchTerm(value);
    }
  };

  return (
    <form className={cn(className, styles.block)} onSubmit={onSubmit}>
      <input
        ref={inputRef}
        className={styles.input}
        id="searchInput"
        type="text"
        placeholder={t("SEARCH.PLACEHOLDER")}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)} />

      <label className={styles.label} htmlFor="searchInput">{t("SEARCH.LABEL")}</label>
    </form>
  );
};

export default SearchMenu;
