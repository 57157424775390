/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";

type Props = {
  title: string,
  description?: string,
  totalCount: number,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
};

type HintProps = {
  title: string,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
};

const ProductListHero = ({
  title,
  description,
  totalCount,
  className,
  breadcrumbLinks }: Props): React$Node => {
  const t = useTranslate();

  return (
    <div
      className={cn(
        styles.block,
        className
      )}
    >
      <Wrapper className={styles.wrapper}>
        <Breadcrumbs
          links={breadcrumbLinks}
          current={title} />

        <div className={styles.text}>
          <h1>{title}</h1>

          {description &&
            <div dangerouslySetInnerHTML={{ __html: description }} className={cn(styles.description, "typography")} />
          }

          <p className={styles.totalCount}>
            {totalCount === 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_SINGULAR")}
            {totalCount > 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_PLURAL", { itemsCount: totalCount })}
          </p>
        </div>
      </Wrapper>
    </div>
  );
};

export const ProductListHeroHint = ({
  title,
  className,
  breadcrumbLinks }: HintProps): React$Node => {
  const t = useTranslate();

  return (
    <div className={cn(styles.block, className)}>
      <Wrapper className={styles.wrapper}>
        <Breadcrumbs
          links={breadcrumbLinks}
          current={title} />
        <div className={styles.text}>
          <h1>{title}</h1>

          <p className={styles.totalCount}>
            {t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_EMPTY")}
          </p>
        </div>
      </Wrapper>
    </div>
  );
};

export default ProductListHero;
