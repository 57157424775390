/* @flow */

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { useUi } from "helpers/ui";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import styles from "./styles.scss";
import Button from "components/Button";
import OffCanvasCategories from "components/OffCanvasCategories";
import CloseIcon from "icons/cross.svg";
import { OffCanvasContainer } from "@crossroads/ui-components";

import SearchIcon from "icons/search.svg";

const TopMenu = ({ isOpen }: { isOpen: boolean }) => {
  const t = useTranslate();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const node = useRef();

  useEffect(() => {
    if (isOpen && document.body && node.current) {
      disableBodyScroll(node.current);
    }

    if (!isOpen && document.body && node.current) {
      enableBodyScroll(node.current);
    }
  }, [isOpen]);

  const onSearch = (e: Event) => {
    e.preventDefault();

    if (!searchQuery || searchQuery.length <= 2) {
      return;
    }

    history.push(`/search/${searchQuery}`);

    setSearchQuery("");
  };

  return (
    <div ref={node} className={styles.topMenu}>
      <form className={styles.search} onSubmit={onSearch}>
        <input
          className={styles.searchInput}
          autoComplete="off"
          placeholder="Sök efter produkter"
          name="query"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <button type="submit" className={styles.searchButton}>
          <SearchIcon className={styles.searchIcon} />
        </button>
      </form>

      <OffCanvasCategories />

      <NavLink
        to={t("CUSTOMER_SERVICE.LINK")}
        className={styles.item}
        activeClassName={styles.isActive}
      >
        <span>{t("CUSTOMER_SERVICE.TEXT")}</span>
      </NavLink>
    </div>
  );
};

const HamburgerMenu = (): React$Node => {
  const t = useTranslate();
  const {
    setHamburgerOpen,
    hamburgerOpen,
  } = useUi();

  const close = () => setHamburgerOpen(false);

  return (
    <OffCanvasContainer
      side="LEFT"
      isOpen={hamburgerOpen}
      close={close}
    >
      <div className={styles.header}>
        <h2>{t("HAMBURGER.TITLE")}</h2>
        <Button
          className={styles.closeButton}
          aria-label="Close Hamburger"
          onClick={close}
        >
          <CloseIcon />
        </Button>
      </div>
      <TopMenu isOpen={hamburgerOpen} />
    </OffCanvasContainer>
  );
};

export default HamburgerMenu;
