/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { PopularRequest, PopularResponse } from "state/popular";

import { POPULAR_REQUEST, POPULAR_RESPONSE } from "state/popular";
import { popular, popularProductsHead as popularProductsHeadQ } from "queries";
import { PAGE_SIZE } from "./route";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: PopularRequest) => {
      const [
        { popularProductsHead },
        { popularProducts },
      ] = await Promise.all([
        client(popularProductsHeadQ, undefined, { cache: false }),
        client(popular, {
          filter: msg.filters,
          page: msg.page !== null ? msg.page : 1,
          pageSize: PAGE_SIZE,
        }, { cache: true }),
      ]);

      return ({
        tag: POPULAR_RESPONSE,
        data: {
          name: popularProductsHead?.category?.name || "",
          description: popularProductsHead?.category?.description || "",
          items: popularProducts,
        },
      }: PopularResponse);
    },
    subscribe: { [POPULAR_REQUEST]: true },
  });
};

export default registerClient;
