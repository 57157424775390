/* @flow */

import type { ProductCardProduct, GAProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useState, useRef, useContext, useEffect, useMemo } from "react";
import { useData, useSendMessage } from "crustate/react";
import { QuoteData } from "data";
import styles from "./styles.scss";
import cn from "classnames";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import { Square } from "@crossroads/ui-components";
import { useCurrentSrc, productIsExternalShowcase, getLowestMSRP } from "helpers/utils";
import { addItem } from "@crossroads/shop-state/quote";
import Button from "components/Button";
import Price from "components/Price";
import { formatConfigColors } from "components/ProductOptions/color";

type ProductCardProps = {
  product: ProductCardProduct,
  className?: string,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

const ProductCard = ({
  product,
  className,
  breadcrumbLinks,
  position,
  list }: ProductCardProps): React$Node => {
  const imageRef = useRef();
  const gaRef: { current: null | Element } = useRef(null);
  const gaContext = useContext(AnalyticsContext);
  const sendMessage = useSendMessage();
  const quoteState = useData(QuoteData).state;
  const [addingToCart, setAddingToCart] = useState(false);
  // Default to smallest image
  const { tinyImage, smallImage, mediumImage, largeImage } = product.attributes;
  const defaultImage = tinyImage || smallImage || mediumImage || largeImage || { x1: "", x2: "" };
  const image = useCurrentSrc(imageRef, defaultImage.x1);
  const msrp = useMemo(() => getLowestMSRP(product), [product]);
  const options = product.type === "configurable" ? product.options.items : [];
  const brand = product.attributes.manufacturer;
  const isExternalShowcase = productIsExternalShowcase(product);
  const hidePrice = product.attributes.externalShowcaseHidePrice ?? false;

  // Set setAddingToCart to false after adding to cart
  useEffect(() => {
    if (quoteState === "LOADED") {
      setAddingToCart(false);
    }
  }, [quoteState]);

  const mapGaObject = (product: ProductCardProduct): GAProduct => {
    const gaObject = {
      item_id: product.sku, // eslint-disable-line camelcase
      item_name: product.name, // eslint-disable-line camelcase
      item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
      price: product.price.incVat,
      index: Number(position) + 1,
      item_list_name: list ?? "", // eslint-disable-line camelcase
    };

    if (product.categories === undefined || product.categories.length === 0) {
      return gaObject;
    }

    product.categories.forEach((c, i) => {
      if (i === 0) {
        gaObject.item_category = c.name; // eslint-disable-line camelcase
      }
      else {
        gaObject[`item_category${i + 1}`] = c.name;
      }
    });

    return gaObject;
  };

  const addToCart = () => {
    setAddingToCart(true);
    gaContext.registerModifyCart({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    }, "add_to_cart", product.price.incVat);

    sendMessage(addItem({ buyRequest: product.buyRequest || "" }, 1));
  };

  const registerClick = () => {
    gaContext.registerProductClick({
      name: product.name,
      sku: product.sku,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    }, list, Number(position) + 1);
  };

  useEffect(() => {
    const gaObject = mapGaObject(product);

    if (!gaRef.current) {
      return;
    }

    gaContext.register(gaRef.current, gaObject);
  }, [gaRef]);

  const to = {
    pathname: product.url,
    state: {
      hint: {
        type: "product",
        product,
        image,
      },
      breadcrumbLinks,
      list,
      position: Number(position) + 1,
    },
  };

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.colors}>
        {options
          .filter(x => Boolean(x.product.attrLabels.color))
          .map((x, i) => (
            <div
              key={i}
              style={{ background: `linear-gradient(${formatConfigColors(x.product.attrLabels.color?.title)})` }} />
          ))}
      </div>

      <div className={styles.header}>
        <ProductLink
          className={styles.imageLink}
          list={list}
          to={to}
          product={{
            name: product.name,
            sku: product.sku,
            price: product.price,
            qty: 1,
            attributes: {
              manufacturer: product.attributes.manufacturer,
            },
            categories: product.categories,
          }}
          innerRef={gaRef}
          position={Number(position) + 1}
        >
          <picture className={styles.imageWrapper}>
            {largeImage &&
              <source
                srcSet={`${largeImage.x1} 1x, ${largeImage.x2} 2x`}
                media={`(min-width: ${styles.large}px)`}
              />
            }
            {mediumImage &&
              <source
                srcSet={`${mediumImage.x1} 1x, ${mediumImage.x2} 2x`}
                media={`(min-width: ${styles.medium}px)`}
              />
            }
            {smallImage &&
              <source
                srcSet={`${smallImage.x1} 1x, ${smallImage.x2} 2x`}
                media={`(min-width: ${styles.small}px)`}
              />
            }
            {tinyImage &&
              <source
                srcSet={`${tinyImage.x1} 1x, ${tinyImage.x2} 2x`}
                media="(min-width: 0px)"
              />
            }
            <img
              ref={imageRef}
              alt={product.name}
              src={image}
              className={styles.image}
              loading="lazy"
            />

            <Square />
          </picture>
        </ProductLink>
      </div>

      <div className={styles.body}>
        <div>
          <span className={styles.name}>{product.name}</span>
          {brand &&
            <div className={styles.brandRow}>
              <span className={styles.brand}>{brand}</span>
            </div>
          }
        </div>
        <div className={cn("row__small", styles.innerBody, { [styles.flexStart]: hidePrice })}>
          <div
            dangerouslySetInnerHTML={{ __html: product.attributes.description }}
            className={styles.description}
          />
          {!hidePrice &&
            <Price
              className={cn(styles.price, "row__small")}
              price={product.price.incVat}
              msrp={msrp || 0}
              productType={product.type}
              hasSelection={false}
              salesLabel={product.attributes.salesLabel}
              url={product.url}
            />
          }
        </div>
        <div className={styles.buttons}>
          <div>
            <Button to={to} variant={isExternalShowcase ? "accent" : "ghost__accent"} onClick={registerClick}>
              Läs mer
            </Button>
          </div>
          {!isExternalShowcase &&
            <div>
              {(product.type === "simple" || product.type === "virtual") &&
                <Button loading={addingToCart} variant="accent" onClick={addToCart}>
                  Köp nu
                </Button>
              }
              {(product.type !== "simple" && product.type !== "virtual") &&
                <Button to={to} variant="accent" onClick={registerClick}>
                  Köp nu
                </Button>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export const DummyCard = ({ className }: { className?: string }): React$Node => {
  return (
    <div className={cn(className, styles.block, styles.dummy)}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <Square />
        </div>
      </div>

      <div className={styles.body}>
        <span className={styles.name}>&nbsp;</span>
        <p className={styles.brand}>&nbsp;</p>

        <div>
          <p className={styles.price}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
