/* @flow */

import React from "react";
import { Route, Switch } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { Helmet } from "react-helmet-async";
import Cart from "components/CheckoutView/Cart";
import Overview from "components/CheckoutView/Overview";
import Wrapper from "components/Wrapper";
import { CmsData } from "data";
import useCheckoutSteps from "helpers/use-checkout-steps";

const CheckoutView = (): React$Node => {
  const t = useTranslate();
  const { currentStep } = useCheckoutSteps();

  return (
    <Wrapper>
      <Helmet
        title={currentStep ? currentStep.title : t("CHECKOUT.STEPS.CART.SHORT")}
      />
      <Switch>
        <CmsData.Provider url={t("CHECKOUT.TERMS_URL")}>
          <Route exact path={t("URL.CHECKOUT_OVERVIEW")} render={() => <Overview />} />
          <Route exact path={t("URL.CHECKOUT_CART")} component={Cart} />
        </CmsData.Provider>
      </Switch>
    </Wrapper>
  );
};

export default CheckoutView;
