/* @flow */

import type { Location } from "react-router-dom";

import type {
  ProductFilterInput,
  ProductFilterInputPrice,
  ProductSortInput,
  FilterableProductList,
} from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import { updateData } from "crustate";
import { getInputFilters, getInputSort, getPage } from "@crossroads/shop-state/filter";

export type PopularProducts = {
  name: string,
  description: string,
  items: FilterableProductList,
};

type Data =
  | { state: "LOADING" }
  | { state: "UPDATING", data: PopularProducts }
  | { state: "LOADED", data: PopularProducts }
  | { state: "ERROR", error: string };

export type PopularResponse = {
  +tag: typeof POPULAR_RESPONSE,
  +data: PopularProducts,
} | EffectErrorMessage;

export type PopularRequest = {
  +tag: typeof POPULAR_REQUEST,
  +url: string,
  +filters: ?Array<ProductFilterInput | ProductFilterInputPrice>,
  +sort: ?ProductSortInput,
  +page: ?number,
};

type LoadReturnType = {|
  +tag: typeof POPULAR_REQUEST,
  +filters: Array<ProductFilterInput | ProductFilterInputPrice>,
  +page: ?number,
  +sort: ?ProductSortInput,
  +url: string,
|};

export const POPULAR_RESPONSE: "response/popular" = "response/popular";
export const POPULAR_REQUEST: "request/popular" = "request/popular";

export const STATE = {
  LOADING: "LOADING",
  UPDATING: "UPDATING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

export const load = (
  location: Location,
  incVat?: boolean,
  pointsToPrice?: number => number): LoadReturnType => {
  const filters = getInputFilters(location, incVat, pointsToPrice);
  const sort = getInputSort(location);
  const page = getPage(location);

  return {
    tag: POPULAR_REQUEST,
    url: location.pathname,
    filters,
    sort,
    page,
  };
};

export const PopularModel: Model<Data,
{ location: Location },
PopularRequest | PopularResponse> = {
  id: "popular",
  init: ({ location }) =>
    updateData({ state: "LOADING" }, load(location)),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case POPULAR_REQUEST:
        if (state.state === STATE.LOADED) {
          return updateData(
            { state: STATE.UPDATING, data: state.data },
            msg
          );
        }

        break;
      case POPULAR_RESPONSE:
        if (state.state === "LOADING" || state.state === "UPDATING") {
          return updateData({ state: STATE.LOADED, data: msg.data });
        }

        throw new Error("Invalid state transition");
      default:
    }
  },
};
