/* @flow */

import { useEffect } from "react";

type Ref = {current: null | React$ElementRef<any>};

const useClickOutside = (ref: Ref, callback: Event => void) => {
  const onClick = e => ref.current &&
    !ref.current.contains(e.target) &&
    typeof callback === "function" &&
    callback(e);

  useEffect(() => {
    if (window) {
      window.addEventListener("click", onClick);

      return () => {
        if (window) {
          window.removeEventListener("click", onClick);
        }
      };
    }
  });
};

export default useClickOutside;
